import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

// interfaces
import { IArticulo } from 'src/app/interfaces/articulo';

// servicios
import { RepartosService } from 'src/app/services/datos/repartos.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';

//librerías
import Swal from 'sweetalert2';
import { HablaService } from 'src/app/services/habla.service';
import { UsuarioService } from 'src/app/services/datos/usuario.service';


/**
 * Distribuye cantidad de un artículo en el cajetín/caja que le corresponda
 */


@Component({
  selector: 'app-prepara-envio3',
  templateUrl: './prepara-envio3.component.html',
  styleUrls: ['./prepara-envio3.component.scss']
})
export class PreparaEnvio3Component implements OnInit {



  @ViewChild('inputCajaField') inputCajaElement!: ElementRef;

  ngAfterViewInit() {
    // Después de que la vista esté inicializada, foco el campo
    this.inputCajaElement.nativeElement.focus();
  }




  id_artic: string = '';
  uic: string = '';

  public artic: any = {};       // Artículo cargado
  public repartos: any[] = [];  // Array de pedidos de este artículo en el carro asignado
  public repartosDeOtros: any[] = [];  // Array de pedidos de este artículo en OTROS carros de la MISMA CAMPAÑA
  public listArticulosCajas: any[] = [];  // Array de cajas con artículos de este pedido

  public totales: any = {};

  public sumaCantidadesARepartir: number = 0;
  public sumaCantidadesRepartidas: number = 0;
  public sumaCantidadesPedido: number = 0;
  public cantEnElCarro: number = 0;
  public sumaArticulosCajas: number = 0;

  public inputUnidades: number = 1;
  public inputUnidadesError: boolean = false;
  public inputCaja: string = '';

  currentIndex: number = 0;           // Valor para rastrear el índice actual de repartos[] 
  private _cajetinActual: any = {};   // Valor desde Getter / Seter
  cajetinesPendientes: number = 0;

  public labelSelect: number = 1; // Etiqueta seleccionada para imprimirla


  loading: boolean = false;

  public fotoDir: string = environment.fotoDir;
  public imgResized: string = localStorage.getItem('imgResized') || '/resize/';
  vozCajetin: boolean = (localStorage.getItem('vozCajetin') === '1') || false;



  constructor(
    private usrSrv: UsuarioService,
    private RepartoSrv: RepartosService,
    private notificacionesSrv: NotificacionesService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public hablaSrv: HablaService
  ) { }


  ngOnInit(): void {
    // Suscribirse a los parámetros de la url
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.uic = params.get('uic') ?? '';
      this.id_artic = params.get('id_artic') ?? '';

      this.cargaArtic();
    });
  }


  cargaArtic() {
    this.loading = true;

    console.log('__getRepartosListUicArt(this.uic, this.id_artic)', this.uic, this.id_artic)

    // this.RepartoSrv.getRepartosListUicArt_FAKE(this.uic, this.id_artic)
    this.RepartoSrv.getRepartosListUicArt(this.uic, this.id_artic)
      .subscribe(resp => {
        this.loading = false;
        console.log('__cargaArtic() resp= ', resp);

        if (Array.isArray(resp.data) && resp.data.length > 0) {
          this.artic = resp.data[0]; // Cargamos el primer elemento en 'artic'
          this.repartos = resp.data;
          this.totales = resp.totales;
          this.repartosDeOtros = this.totales.repartosDeOtros;
          this.listArticulosCajas = this.totales.listArticulosCajas;

          this.cajetinActual = this.getCajetinActual();
          console.log('__cajetinActual=', this.cajetinActual);
          console.log('__repartosDeOtros=', this.repartosDeOtros);
          console.log('__listArticulosCajas=', this.listArticulosCajas);


        } else {
          this.notificacionesSrv.aviso('error', `No se ha encontrado el artículo con id  ${this.id_artic} en ${this.uic}`);
          this.router.navigate(['./pda']);
          return;
        }

        if (resp.error) this.notificacionesSrv.aviso('error', resp.mensaje);
        this.actualizaTotalesRepartidos()
      });

  }

  actualizaTotalesRepartidos() {
    // Actualizar totales repartidos

    this.sumaCantidadesARepartir = this.repartos.reduce((acumulador, reparto) => {
      return parseInt(acumulador) + parseInt(reparto.cant_repartir);
    }, 0);

    this.sumaCantidadesRepartidas = this.repartos.reduce((acumulador, reparto) => {
      return parseInt(acumulador) + parseInt(reparto.cant_repartida);
    }, 0);

    this.sumaCantidadesPedido = this.repartos.reduce((acumulador, reparto) => {
      return parseInt(acumulador) + parseInt(reparto.cant_pedido);
    }, 0);

    this.cantEnElCarro = this.sumaCantidadesARepartir - this.sumaCantidadesRepartidas;

    this.sumaArticulosCajas = this.listArticulosCajas.reduce((sum, item) => sum + Number(item.cantidad), 0);

  }


  prnEtiqueta() {
    Swal.fire({
      title: 'Imprimir etiqueta',
      icon: 'question',
      html: `¿Queres imprimir la etiqueta para la caja <span class="font-weight-bold text-warning">${this.labelSelect}</span> del cajetín <span class="font-weight-bold text-success">${this.cajetinActual.cajetin}</span>?`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si',
    }).then((result) => {
      if (result.isConfirmed) {
        console.log('Imprimir etiqueta ', this.labelSelect)
        this.pdfEtiqueta(this.labelSelect);
      }
    })
  }

  inputCajaEnter() {
    // Enter en inputCaja
    const inputCaja = this.inputCaja.trim();

    // Opción 1, Saltar al siguiente cajetín  //
    if (inputCaja === '7777') {

      this.cajetinesPendientes = this.repartos.filter(item => (item.cant_repartir - item.cant_repartida) > 0).length;

      console.log('this.repartos', this.repartos);
      console.log('cajetinesPendientes.length', this.cajetinesPendientes);

      if (this.cajetinesPendientes === 1) {
        this.notificacionesSrv.aviso('warning', 'No hay más cajetínes pendientes para este artículo.');
        this.inputUnidadesError = false;
        this.inputUnidades = 1;
        this.inputCaja = '';
        return
      }

      this.currentIndex = (this.currentIndex + 1) % this.repartos.length;
      this.cajetinActual = this.getCajetinActual();

      this.inputCaja = '';
      this.inputUnidades = 1;
      return
    }

    // Opción 2, Añadir artículos a un cajetín caja //


    // Validar y cargar valores de cajetín y caja
    const partes = inputCaja.split(' ');

    if (partes.length !== 2) {
      this.notificacionesSrv.aviso('error', 'Formato no válido. Debe contener cajetín espacio caja.');

      this.inputCaja = '';
      return
    }

    const cajetinLeido = partes[0];
    const cajaLeido = partes[1];
    if (cajetinLeido !== this.cajetinActual.cajetin) {
      this.notificacionesSrv.aviso('error', `Leído cajetín ${cajetinLeido}, NO es el esperado`);

      this.inputCaja = '';
      return
    }

    if (cajaLeido > this.cajetinActual.max_cajas) {
      this.notificacionesSrv.aviso('error', `Caja ${cajaLeido}, NO existe para este cajetín`);

      this.inputCaja = '';
      return
    }


    // Validar cantidad
    this.inputUnidadesError = false;
    const cant_pendiente = this.cajetinActual.cant_repartir - this.cajetinActual.cant_repartida;

    if (this.inputUnidades < 1 || this.inputUnidades > cant_pendiente) {
      this.inputUnidadesError = true;
      return
    } else {
      this.inputUnidadesError = false;
    }


    // Todo ok, Llamada a la API

    const data = {
      id_reparto: this.cajetinActual.id,
      id_pedido: this.cajetinActual.id_pedido,
      id_preparacion: this.cajetinActual.id_preparacion,
      id_articulo: this.cajetinActual.id_articulo,
      id_usuario: this.usrSrv.userdata.id,
      cantidad: this.inputUnidades,
      caja_num: cajaLeido
    };

    this.loading = true;
    this.RepartoSrv.addCantToCaja(data)
      .subscribe(resp => {

        console.log('__addCantToCaja data', data)
        console.log('__addCantToCaja resp', resp)

        this.loading = false;
        if (resp.error) {
          this.notificacionesSrv.aviso('error', resp.mensaje);
          return
        } else {
          this.inputUnidades = 1;
          //Se ha acabado el pedido?
          if (resp.data.nombrePdf) {
            if (resp.data.cajasVacias.length) {
              this.notificacionesSrv.aviso('warning', `Cajetín completado, se ha generado el albarán ${resp.data.nombrePdf}<br>Contiene las cajas (${resp.data.cajasVacias}) vacias`);
            } else {
              this.notificacionesSrv.aviso('info', `Cajetín completado, se ha generado el albarán ${resp.data.nombrePdf}`);
            }
          }

          // Quedan en este UIC repartos pendientes de este artículo en otros pedidos?
          if (resp.data.repartosPendientes == 0) {
            this.notificacionesSrv.aviso('success', `Artículo ${this.artic.articulo?.ean} completado`);
            this.router.navigate([`./pda/prepara_envio2/${this.uic}`]);
            return;
          }

          this.cargaArtic();
          this.inputCaja = '';
          return

        }
      });




  }

  /////////////   AUX   //////////

  getCajetinActual() {
    // Variables de estado
    const repartos = this.repartos;
    const repartosLength = repartos.length;
    let currentIndex = this.currentIndex;       // Guardo el índice actual 


    // Función para comprobar si un reparto está acabado  
    const isRepartoAcabado = (reparto: any) => {
      const repartoAcabado = (reparto.cant_repartida - reparto.cant_repartir === 0)
      return repartoAcabado
    };

    // Avanzar al siguiente cajetín si el actual está acabado
    while (isRepartoAcabado(repartos[currentIndex])) {
      currentIndex = (currentIndex + 1) % repartosLength;
    }

    // Devolver el cajetín actual
    const cajetinActual = repartos[currentIndex];

    // Almacena el índice actual después de asegurarse de que no esté acabado
    this.currentIndex = currentIndex;


    return cajetinActual;
  }



  // Geter/Seter Cajetin
  get cajetinActual() {
    return this._cajetinActual;
  }
  set cajetinActual(value) {
    const valorAnterior = this._cajetinActual.cajetin;
    this._cajetinActual = value;

    // Verificar si el valor ha cambiado y hay que anunciarlo
    if (valorAnterior !== value.cajetin) {
      if (this.vozCajetin) this.hablaSrv.habla('Ves al cajetín ' + this.cajetinActual.cajetin);
    }
  }



  addCaja() {
    // Hacer el insert en la tabla repartos_cajas
    const data = {
      id_pedido: this.cajetinActual.id_pedido,
      id_articulo: this.cajetinActual.id_articulo,
      id_usuario: this.usrSrv.userdata.id
    };
    this.loading = true;
    this.RepartoSrv.addCaja(data)
      .subscribe(resp => {
        console.log('__addCaja', resp)
        this.loading = false;
        if (resp.error) {
          this.notificacionesSrv.aviso('error', resp.mensaje);
        } else {
          this.cajetinActual.max_cajas = resp.data.caja_num;
          console.log('Imprimir etiqueta ', this.cajetinActual.max_cajas)
        }
      });
  }

  generaArrayAux(): number[] {
    // genera un array[] con valores de 1 a this.cajetinActual.max_cajas.length para el select de prnEtiqueta()
    return Array.from({ length: this.cajetinActual.max_cajas }, (_, i) => i + 1);
  }


  pdfEtiqueta(caja_num: number) {
    // Imprimir una etiqueta en concreto
    const data = {
      id_pedido: this.cajetinActual.id_pedido,
      id_articulo: this.cajetinActual.id_articulo,
      id_usuario: this.usrSrv.userdata.id,
      caja_num: caja_num
    };

    this.loading = true;
    this.RepartoSrv.pdfEtiqueta(data)
      .subscribe(resp => {
        console.log('__pdfEtiqueta', resp)
        this.loading = false;
        if (resp.error) {
          this.notificacionesSrv.aviso('error', resp.mensaje);
        }
      });
  }

  volverClick() {
    this.router.navigate([`./pda/prepara_envio2/${this.uic}`]);
  }

}




