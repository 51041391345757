<div class="row page-titles">
    <div class="col-md-5 align-self-center">
        <h3 class="text-themecolor">Recoger preparación:</h3>
        <h3 class="text-themecolor">

            <img *ngIf="campanya.campanyaTipo === '1 a 1'"
                 src="../../../assets/images/icon/1a1.png"
                 height="30px"
                 alt="1 a 1"
                 class="mr-1">
            {{recogida_cod}} ({{currentIndex+1}} / {{totalUbis}})
        </h3>

        <h4 *ngIf="registroActual.uic"> <label class="form-control-label">
                <i>Uic: </i><i class="fa-solid fa-dolly"></i> {{registroActual.uic}}</label>
        </h4>
    </div>
</div>

<div class="row animated fadeIn fast">
    <div class="col-lg-12">
        <div class="card">


            <div class="card-body">

                <form class="form p-t-20"
                      [formGroup]="formRec"
                      (ngSubmit)="enviar()"
                      class="form-horizontal">



                    <!-- PRIMERA  parte del form, pedir uic -->
                    <div class="row"
                         *ngIf="!registroActual.uic">


                        <!-- uic -->
                        <div class="col-md-4">
                            <div class="form-group"
                                 [ngClass]="{ 'has-danger': enviado && f['uic'].errors }">
                                <label class="form-control-label">UIC: <b>{{registroActual.uic}}</b></label>

                                <div class="input-group">

                                    <div *ngIf=" f['uic'].value"
                                         class="input-group-prepend pointer">
                                        <button class="btn btn-outline-secondary"
                                                (click)="limpiaUic()"
                                                type="button">
                                            <i class="fa fa-trash"
                                               aria-hidden="true"></i>
                                        </button>
                                    </div>

                                    <input type="text"
                                           #uicInput
                                           formControlName="uic"
                                           class="form-control"
                                           trim="blur"
                                           (keydown.enter)="onUicEnter($event)"
                                           autofocus
                                           (focus)="uicInput.select()"
                                           [ngClass]="{ 'form-control-danger': enviado && f['uic'].errors }">

                                    <div *ngIf=" f['uic'].value"
                                         class="input-group-append">
                                        <button class="btn btn-outline-secondary"
                                                (click)="saltaUic(uicInput)"
                                                type="button"
                                                id="button-addon2"><i class="fa-solid fa-check"></i></button>
                                    </div>

                                </div>

                                <div *ngIf="enviado && f['uic'].errors"
                                     class="form-control-feedback">
                                    <div *ngIf="f['uic'].errors['required']">Obligatorio</div>
                                    <div *ngIf="f['uic'].errors['formatoIncorrecto']">Formato incorrecto</div>

                                    <div *ngIf="formRec.get('uic')?.errors?.['apiError']">
                                        {{formRec.get('uic')?.errors?.['apiError'] }}
                                    </div>

                                </div>


                            </div>
                        </div>

                    </div> <!-- row -->





                    <!-- SEGUNDA  parte del form se mostrará si existe uic pero aún NO hay Ubicación -->


                    <div class="row"
                         *ngIf="(formRec.get('uic')?.value == registroActual?.uic) && (formRec.get('ubicacion')?.value !== registroActual?.ubicacion)">


                        <!-- <div class="row"> -->
                        <div class="col-md-4">
                            <div class="form-group"
                                 [ngClass]="{ 'has-danger': enviado && f['ubicacion'].errors }">
                                <label class="form-control-label">
                                    Ubicación: <b>{{registroActual.ubiFormat}}</b>
                                </label>
                                <div class="input-group">
                                    <div class="input-group-prepend pointer">
                                        <button class="btn btn-outline-secondary"
                                                (click)="buscaUbicacionAlternativa()"
                                                type="button">
                                            <i class="fa-regular fa-compass fa-xl"></i>
                                        </button>
                                    </div>
                                    <input type="text"
                                           formControlName="ubicacion"
                                           id="ubicacion"
                                           class="form-control"
                                           trim="blur"
                                           (keydown.enter)="onUbicacionEnter($event)"
                                           [ngClass]="{ 'form-control-danger': enviado && f['ubicacion'].errors }">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary"
                                                (click)="saltaUbicacion()"
                                                type="button"
                                                id="button-addon2"><i class="fa-solid fa-check"></i></button>
                                    </div>


                                </div>
                                <div *ngIf="enviado && f['ubicacion'].errors"
                                     class="form-control-feedback">
                                    <div *ngIf="f['ubicacion'].errors['required']">Obligatorio</div>
                                    <div *ngIf="f['ubicacion'].errors['noValido']">No es la ubicación correcta</div>
                                </div>
                            </div>
                        </div>

                    </div>



                    <!-- Tercera  parte del form que se mostrará si ubicacion es igual a registroActual.ubicacion -->

                    <div class="row"
                         *ngIf="formRec.get('ubicacion')?.value === registroActual?.ubicacion">


                        <div class="col-md-4">
                            <h4 class="card-title">
                                <button class="btn btn-outline-secondary"
                                        (click)="buscaUbicacionAlternativa()"
                                        type="button">
                                    <i class="fa-regular fa-compass "></i>
                                </button>
                                Ubicación: <b>{{registroActual.ubiFormat}}</b>
                            </h4>
                            <h4 class="card-title">Ref/SKU: <b>{{registroActual.sku}}</b></h4>
                            <h4 class="card-title">Ean: <b>{{registroActual.ean}}</b></h4>

                            <div *ngIf="registroActual.foto != '_noFoto.png'"
                                 class="text-center">
                                <img [src]="fotoDir +'/articulos' + imgResized + registroActual.foto"
                                     loading="lazy"
                                     alt="Foto"
                                     class="img-thumbnail mx-auto"
                                     style="max-width: 35% !important;"
                                     title="foto">
                            </div>

                            <h4 class="card-subtitle"><b>{{registroActual.descripcion}}</b></h4>

                            <div class="row border mb-1">
                                <div class="col-4 mt-2">
                                    <h6 class="card-subtitle">Cantidad pedida:</h6><b class="font-larger text-right">{{registroActual.cant_reserva | number: '0.0-0':'es'}}</b>
                                </div>
                                <div class="col-4 mt-2">
                                    <h6 class="card-subtitle">Cantidad recogida:</h6><b class="font-larger text-right">{{registroActual.cant_recogida | number: '0.0-0':'es'}}</b>
                                </div>
                                <div class="col-4 mt-2">
                                    <h6 class="card-subtitle">Stock en ubicación:</h6><b class="font-larger text-right">{{registroActual.stockUbi | number: '0.0-0':'es'}}</b>
                                </div>
                            </div>


                        </div> <!-- row -->


                        <div class="row">

                            <!-- cant_alCarro -->
                            <div class="form-group col-4"
                                 [ngClass]="{ 'has-danger':f['cant_alCarro'].errors }">
                                <label class="form-control-label">Cantidad:</label>
                                <input type="text"
                                       formControlName="cant_alCarro"
                                       id="cant_alCarro"
                                       class="form-control"
                                       trim="blur"
                                       [ngClass]="{ 'form-control-danger': f['cant_alCarro'].errors }">

                                <div *ngIf="f['cant_alCarro'].errors"
                                     class="form-control-feedback">
                                    <div *ngIf="f['cant_alCarro'].errors['required']"></div>
                                    <div *ngIf="f['cant_alCarro'].errors['cantidadInvalida']">Inválida max. {{registroActual.cant_reserva - registroActual.cant_recogida | number: '0.0-0':'es'}}</div>
                                </div>
                            </div>





                            <!-- ean -->
                            <div class="form-group col-8"
                                 [ngClass]="{ 'has-danger': f['ean'].errors }">
                                <label class="form-control-label">EAN: <b>{{registroActual.ean}}</b></label>

                                <div class="input-group">
                                    <input type="text"
                                           formControlName="ean"
                                           id="ean"
                                           class="form-control"
                                           trim="blur"
                                           [ngClass]="{ 'form-control-danger': f['ean'].errors }">

                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary"
                                                (click)="saltaEan()"
                                                type="button"
                                                id="button-addon2"><i class="fa-solid fa-check"></i></button>
                                    </div>
                                </div>
                                <div *ngIf=" f['ean'].errors"
                                     class="form-control-feedback">
                                    <!-- <div *ngIf="f['ean'].errors['required']">Obligatorio</div> -->
                                </div>
                            </div>



                        </div> <!-- row -->


                    </div>




                    <hr style="margin-top: 0rem;">

                    <div *ngIf="registroActual.uic"
                         class="row">

                        <div class="col-5">
                            <button [disabled]="loading"
                                    type="button"
                                    (click)="carroLlenoClick()"
                                    class="btn waves-effect waves-light btn-rounded btn-success btn-sm">
                                <i *ngIf="loading"
                                   class="fa fa-spinner fa-spin"></i>
                                Carro lleno</button>


                            <button [disabled]="loading"
                                    type="button"
                                    (click)="volverClick()"
                                    class="ml-1 btn waves-effect waves-light btn-rounded btn-secondary btn-sm">
                                <i *ngIf="loading"
                                   class="fa fa-spinner fa-spin"></i>
                                Volver</button>
                        </div>


                        <div *ngIf="formRec.get('ubicacion')?.value === registroActual?.ubicacion"
                             class="col-7 text-right">
                            <div class="input-group">
                                <select class="custom-select form-control-sm"
                                        formControlName="saltar"
                                        id="saltar">
                                    <option value="Falta stock">Falta stock</option>
                                    <option value="Stock dañado">Stock dañado</option>
                                    <option value="Sin acceso">Sin acceso</option>
                                    <option value="Otros">Otros</option>
                                </select>
                                <div class="input-group-append">
                                    <button type="button"
                                            class="btn waves-effect waves-light btn-outline-secondary btn-sm"
                                            (click)="saltarArticuloClick()"
                                            data-dismiss="modal">
                                        <i *ngIf="loading"
                                           class="fa fa-spinner fa-spin"></i>Saltar</button>
                                </div>
                            </div>
                        </div>




                        <div class="col-7 text-right"
                             *ngIf="formRec.get('ubicacion')?.value !== registroActual?.ubicacion">
                            <button [disabled]="loading"
                                    type="button"
                                    (click)="cerrarPreparacion()"
                                    class="btn waves-effect waves-light btn-rounded btn-warning btn-sm">
                                <i *ngIf="loading"
                                   class="fa fa-spinner fa-spin"></i>
                                Cerrar preparación</button>
                        </div>





                    </div> <!-- row -->






                </form>
            </div>
        </div>


        <!-- 
            <div class="row">
                <pre>
                    registroActual: {{ registroActual | json }} 
                    formRec: {{ formRec.value | json }} 
                </pre>
            </div> 
        -->



    </div>
</div>





<!-- Resumen de la preparación -->


<div class="col-lg-12"
     style="padding: 1px">

    <div class="card"
         *ngFor="let uic of getPreparacionesAgrupadasKeys()">

        <!-- Articulos recogidos -->
        <span *ngIf="uic!='Incidencias'">

            <h5 class="text-success"> <i class="fa-solid fa-dolly"></i> {{ uic }}</h5>
            <table class="table table-sm">
                <thead>
                    <tr>
                        <th>EAN</th>
                        <th>Sku</th>
                        <th class="text-right">Cant.</th>
                        <th class="text-right">Recg.</th>
                        <th>Ubicación</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let recPrep of preparacionesAgrupadas[uic]"
                        [ngClass]="{'bg-warning': recPrep.estado=='8'}">
                        <td>{{ recPrep.ean }}</td>
                        <td>{{ recPrep.sku }}</td>
                        <td class="text-right">
                            <ng-container *ngIf="recPrep.cant_inicial == 0">
                                {{ recPrep.cant_reserva | number: '0.0-0':'es' }}
                            </ng-container>
                            <ng-container *ngIf="recPrep.cant_inicial != 0">
                                {{ recPrep.cant_inicial | number: '0.0-0':'es' }}
                            </ng-container>
                        </td>
                        <td class="text-right"
                            [ngClass]="{
                            'bg-blue rounded': recPrep.estado === Constantes.ESTADO_PREPARACION_EMPEZADO,
                            'bg-green rounded': recPrep.estado ===  Constantes.ESTADO_PREPARACION_COMPLETADA
                         }">{{ recPrep.cant_recogida | number: '0.0-0':'es' }}</td>
                        <td class="monospace"> {{ recPrep.ubicacion! | mask: '00.00.00.00.00.00' }}</td>
                    </tr>
                </tbody>
            </table>

        </span>


        <!-- Artúculos pendientes -->
        <span *ngIf="uic=='Incidencias'">
            <details>
                <summary>Ubicaciones pendientes</summary>
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th>EAN</th>
                            <th>Sku</th>
                            <th class="text-right">Cantidad</th>
                            <th>Ubicación</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let recPrep of preparacionesAgrupadas[uic]"
                            [ngClass]="{'bg-warning': recPrep.estado=='8'}">
                            <td>{{ recPrep.ean }}</td>
                            <td>{{ recPrep.sku }}</td>
                            <td class="text-right">
                                <ng-container *ngIf="recPrep.cant_inicial == 0">
                                    {{ recPrep.cant_reserva | number: '0.0-0':'es' }}
                                </ng-container>
                                <ng-container *ngIf="recPrep.cant_inicial != 0">
                                    {{ recPrep.cant_inicial | number: '0.0-0':'es' }}
                                </ng-container>
                            </td>
                            <td class="monospace"> {{ recPrep.ubicacion! | mask: '00.00.00.00.00.00' }}</td>
                        </tr>
                    </tbody>
                </table>
            </details>
        </span>



    </div>

</div>